import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { JwtGeneratorService } from "./jwt-generator.service";
import { roles } from "./roles.data";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  qrData;
  userRoles = roles;
  userForm = this.fb.group({
    familyName: ["", Validators.required],
    givenName: ["", Validators.required],
    email: ["", Validators.required],
    roles: this.buildRoles()
  });

  constructor(
    private fb: FormBuilder,
    private jwtGenerator: JwtGeneratorService
  ) {}

  ngOnInit(): void {
    this.userForm.valueChanges.subscribe(val => (this.qrData = null));
  }

  buildRoles() {
    const arr = this.userRoles.map(() => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }

  get roles() {
    return this.userForm.get("roles");
  }

  onSubmit(form: any) {
    const roles = [];
    form.value.roles.forEach((selected, i) => {
      if (selected) {
        roles.push(this.userRoles[i].value);
      }
    });
    form.value.roles = roles;
    this.qrData = this.jwtGenerator.generateQRData(form.value);
  }
}
