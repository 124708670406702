import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root"
})
export class JwtGeneratorService {
  secret = "secret"; // ?

  constructor() {}

  generateQRData(form: any) {
    const template = {
      aud: "https://kpmg.customer.space.one",
      iss: "http://kpmg-fake-login.devdock.space.one",
      iat: Math.round(Date.now() / 1000), // UNIX timestamp in seconds
      exp: Math.round(Date.now() / 1000) + 28800,
      family_name: form.familyName,
      given_name: form.givenName,
      upn: form.email,
      unique_name: form.email,
      role: form.roles
    };

    var stringifiedHeader = CryptoJS.enc.Utf8.parse(
      JSON.stringify({
        alg: "HS256",
        typ: "JWT"
      })
    );
    var encodedHeader = this.base64url(stringifiedHeader);

    const stringifiedTemplate = CryptoJS.enc.Utf8.parse(
      JSON.stringify(template)
    );
    const encodedTemplate = this.base64url(stringifiedTemplate);

    const token = encodedHeader + "." + encodedTemplate;

    let signature = CryptoJS.HmacSHA256(token, this.secret);

    const signedToken = token + "." + this.base64url(signature);

    return JSON.stringify({
      access_token: signedToken,
      token_type: "Bearer",
      expires_in: 28800
    });
  }

  base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, "");

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, "-");
    encodedSource = encodedSource.replace(/\//g, "_");

    return encodedSource;
  }
}
