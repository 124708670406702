export const roles = [
  {
    name: "DE-SG ACom Partner",
    value: "83515f8f-8345-4f47-b222-13c92af9dabb"
  },
  {
    name: "DE-SG ACom Direktoren",
    value: "a4db5df5-09f7-4feb-aab4-3715a8c50d2b"
  },
  {
    name: "DE-SG ACom Manager",
    value: "a3a55f0d-2905-4f5b-8737-a2cc2bb146cc"
  },
  {
    name: "DE-SG ACom Staff",
    value: "c7375b72-d39c-4deb-a75e-7c2279bf3ada"
  },
  {
    name: "DE-SG ACom Redakteure",
    value: "67fd82f3-56b8-483f-aa6b-f316823f1b07"
  },
  {
    name: "DE-SG ACom Corporate Governance Services",
    value: "1bc3a523-47e6-4d55-8a8d-43d0f8e3059f"
  },
  {
    name: "DE-SG ACom Audit Attestation",
    value: "2717f041-84c2-4be5-a151-d8c07829bd78"
  },
  {
    name: "DE-SG ACom Finance Advisory",
    value: "915c7b98-dd3c-4349-9823-1949f9d0638e"
  },
  {
    name: "DE-SG ACom Audit Support Unit",
    value: "217a7eab-0814-4fc7-bf68-f07e715f5837"
  }
];
